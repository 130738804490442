function path(root, sublink) {
    return `${root}${sublink}`;
  }
  const ROOTS = {
    auth: '',
    app: '/app'
  };
  export const PATH_PAGE = {
    auth: {
      root: ROOTS.auth,
      login: path(ROOTS.auth, '/login'),
      resetPassword: path(ROOTS.auth, '/reset-password')
    }
  };
  export const PATH_APP = {
    root: ROOTS.app,
    dashboard: path(ROOTS.app, '/dashboard'),
    addPatient: path(ROOTS.app, '/add-patient'),
    editPatient: path(ROOTS.app, '/edit-patient'),
    patients: path(ROOTS.app, '/patients'),
    configuration: path(ROOTS.app, '/configuration'),
    admin: path(ROOTS.app, '/admin')
  };
  