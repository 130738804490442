import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Link,
  Grid,
} from "@mui/material";

import { spacing } from "@mui/system";
import useDataUser from "hooks/useDataUser";
import UsersGrid from "./UsersGrid";
import UserEditBtn from "./UserEditBtn";
// ----------------------------------------------------------------------
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export default function UsersPage() {
  const { initUsers } = useDataUser();
  useEffect(() => {
    initUsers();
  }, []);

  return (
    <>
      <Helmet title="Utilisateurs" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Utilisateurs
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/app">
              Accueil
            </Link>
            <Typography>Utilisateurs</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <UserEditBtn />
        </Grid>
      </Grid>

      <Divider my={6} />
       <Grid container spacing={6}>
        <Grid item xs={12}>
          <UsersGrid />
        </Grid>
      </Grid>
    </>
  );
}
 
