import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import LoadingScreen from "components/LoadingScreen";
// Auth components
import RequireAuth from "components/auth/RequireAuth";
import RequireRole from "components/auth/RequireRole";
import RequireGuest from "components/auth/RequireGuest";
import AuthLayout from "layouts/Auth";
import DashboardLayout from "layouts/Dashboard";

import Landing from "pages/presentation/Landing";
import SignIn from "pages/auth/SignIn";
import Page404 from "pages/auth/Page404";
import Page500 from "pages/auth/Page500";
import Page401 from "pages/auth/Page401";
//users
import UsersPage from "pages/users/UsersPage";
import RefCompaniesPage from "pages/refs/RefCompaniesPage";
// ----------------------------------------------------------------------
const ROLES_SUPERADMIN = ["superadmin"];
const ROLES_ADMIN = ["superadmin", "admin"];
export default function Router() {
  const appRoutes = useRoutes([
    {
      path: "/app",
      element: (
        <RequireAuth>
          <DashboardLayout />
        </RequireAuth>
      ),
      children: [
        { path: "", element: <Landing /> },
        { path: "dashboard", element: <Landing /> },
        {
          path: "users",
          element: (
            <RequireRole roles={ROLES_SUPERADMIN}>
              <UsersPage />
            </RequireRole>
          ),
        },
        {
          path: "companies",
          element: (
            <RequireRole roles={ROLES_SUPERADMIN}>
              <RefCompaniesPage />
            </RequireRole>
          ),
        },
      ],
    },
    /* general */
    {
      path: "/",
      element: (
        <RequireGuest>
          <AuthLayout />
        </RequireGuest>
      ),
      children: [
        { path: "", element: <SignIn /> },
        { path: "login", element: <SignIn /> },
      ],
    },
    { path: "401", element: <Page401 /> },
    { path: "500", element: <Page500 /> },
    { path: "404", element: <Page404 /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
  return (
    <React.Suspense fallback={<LoadingScreen />}>{appRoutes}</React.Suspense>
  );
}
