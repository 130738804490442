import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  users: {},
};
const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    setInitialize(state, action) {
      state.isLoading = false;
      state.users = action.payload.users;
    },
  },
});
export const { startLoading, setInitialize } = userSlice.actions;
const userReducer = userSlice.reducer;

export default userReducer;
