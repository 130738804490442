import { useDispatch, useSelector } from "react-redux";
import { startLoading, setInitialize } from "redux/slices/refSlice";
import axios from "axios";
import { API_URL } from "config";

// ----------------------------------------------------------------------

export default function useReferentiel() {
  const dispatch = useDispatch();
  const { refs } = useSelector((state) => state.ref);

  return {
    refs,
    initRefs: async () => {
      try {
        dispatch(startLoading());
        const res = await axios.get(API_URL.refs);
        if (res && res.data) {
          const refs = res.data;
          dispatch(setInitialize({ refs }));
        } else {
          throw new Error("Référentiels ne sont pas disponibles");
        }
      } catch (error) {
        console.error();
        throw new Error("Référentiels ne sont pas disponibles");
      }
    },
    saveRef: async (o, refName) => {
      try {
        console.log(API_URL[refName]);
        delete o.modifiedby;
        delete o.createdby;
        if (o && o.id) {
          await axios.patch(API_URL[refName] + "/" + o.id, o);
        } else {
          await axios.post(API_URL[refName], o);
        }
      } catch (error) {
        console.error();
        throw error;
      }
    },
    delRef: async (o, refName) => {
      try {
        if (o && o.id) {
          await axios.delete(API_URL[refName] + "/" + o.id);
        }
      } catch (error) {
        console.error();
        throw error;
      }
    },
  };
}
