import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";
import { PersistGate } from "redux-persist/lib/integration/react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { createBrowserHistory } from "history";
import LoadingScreen from "components/LoadingScreen";
import AppProvider from "provider/AppProvider";
import NotistackProvider from "./provider/NotistackProvider";
import "./i18n";
import createTheme from "./theme";
import Router from "./routes";

import useTheme from "./hooks/useTheme";
import { store, persistor } from "./redux/store";
import createEmotionCache from "./utils/createEmotionCache";

const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) {
  const { theme } = useTheme();

  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet
          titleTemplate="%s | Oauth"
          defaultTitle="Oauth - React Material Admin Dashboard"
        />
        <ReduxProvider store={store}>
          <PersistGate loading={<LoadingScreen />} persistor={persistor}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MuiThemeProvider theme={createTheme(theme)}>
                <NotistackProvider>
                  <AppProvider>
                    <Router history={history} />
                  </AppProvider>
                </NotistackProvider>
              </MuiThemeProvider>
            </LocalizationProvider>
          </PersistGate>
        </ReduxProvider>
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;
