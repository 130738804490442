import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import authReducer from "./slices/authSlice";
import refReducer from "./slices/refSlice";
import userReducer from "./slices/userSlice";
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: ["settings"],
};

const rootReducer = combineReducers({
  auth: authReducer,
  ref: refReducer,
  users: userReducer,
});

export { rootPersistConfig, rootReducer };
