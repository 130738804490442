import {
  BookOpen,
  Briefcase,
  Calendar,
  CheckSquare,
  CreditCard,
  Grid,
  Heart,
  Layout,
  List,
  Map,
  ShoppingCart,
  PieChart,
  Sliders,
  Users,
} from "react-feather";

const pagesSection = [
  {
    href: "/app/users",
    icon: Users,
    title: "Utilisateurs",
    children: [
      {
        href: "/app/users",
        title: "Tous",
      },
    ],
  },
  /* {
    href: "/pages",
    icon: Layout,
    title: "Codes d'application",
    children: [
      {
        href: "/app/appcodes",
        title: "Codes",
      },
    ],
  }, */
];

const companiesSection = [
  {
    href: "/app/companies",
    icon: Layout,
    title: "Companies",
    children: [
      {
        href: "/app/companies",
        title: "Tous",
      },
    ],
  },
];

const docsSection = [
  {
    href: "/documentation/welcome",
    icon: BookOpen,
    title: "Documentation",
  },
  {
    href: "/changelog",
    icon: List,
    title: "Changelog",
    badge: "v4.2.0",
  },
];

const navItems = [
  {
    title: "Utilisateurs et codes",
    pages: pagesSection,
  },
  {
    title: "Companies",
    pages: companiesSection,
  },

  /*   {
    title: "Elements",
    pages: elementsSection,
  },
  {
    title: "Oauth Pro",
    pages: docsSection,
  }, */
];

export default navItems;
