import React, { useEffect, useState } from "react";
import faker from "faker";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PasswordIcon from "@mui/icons-material/Password";
import Tooltip from "@mui/material/Tooltip";
// ----------------------------------------------------------------------

export default function CodeGen({
  value,
  placeholder,
  label,
  title,
  onChange,
  error,
}) {
  const [code, setcode] = useState("");
  useEffect(() => {
    setcode(value);
  }, [value]);

  const handleGenerate = () => {
    const rd = faker.random.alpha(10);
    onChange(rd);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
      <InputLabel htmlFor="outlined-adornment-password">
        {label ? label : "Mot de passe"}
      </InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        placeholder={placeholder ? placeholder : ""}
        type="text"
        value={code}
        onChange={handleChange}
        endAdornment={
          <InputAdornment position="end">
            <Tooltip title={title ? title : "Générer le code"}>
              <IconButton
                aria-label="code generate"
                onClick={handleGenerate}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                <PasswordIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        }
        label={label}
        error={error}
      />
    </FormControl>
  );
}
