import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import { composeWithDevTools } from "@redux-devtools/extension";
import { rootPersistConfig, rootReducer } from "./rootReducer";

const store = configureStore(
  {
    reducer: persistReducer(rootPersistConfig, rootReducer),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }),
  },
  composeWithDevTools()
);
const persistor = persistStore(store);

export { store, persistor };
