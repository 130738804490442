import "react-app-polyfill/stable";

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
//handler error on app
import { ErrorBoundary } from "react-error-boundary";
import "chart.js/auto";

import App from "./App";
import reportWebVitals from "./utils/reportWebVitals";
import { ThemeProvider } from "./contexts/ThemeContext";
import ErrorAppFallback from "./pages/error/ErrorAppFallback";

// Note: Remove the following line if you want to disable the API mocks.
import "./mocks";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <ErrorBoundary
      FallbackComponent={ErrorAppFallback}
      onReset={() => {}}
      onError={(error, errorInfo) => console.error({ error, errorInfo })}
    >
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </ErrorBoundary>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
