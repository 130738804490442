import React from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  MenuItem,
  TextField as MuiTextField,
  FormHelperText,
  ListSubheader,
} from "@mui/material";
import { spacing } from "@mui/system";

import useNotification from "hooks/useNotification";
import useReferentiel from "hooks/useReferentiel";
import useDataUser from "hooks/useDataUser";
import CodeGen from "components/CodeGen";
// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
export default function UserForm({ editUser, onComplete }) {
  const { saveUser, initUsers } = useDataUser();
  const { notif } = useNotification();
  const { refs } = useReferentiel();
  const UserSchema = Yup.object().shape({
    pseudo: Yup.string().max(255).required("Veuillez renseigner le pseudo"),
    email: Yup.string()
      .email("Fomat d'email invalide")
      .max(255)
      .required("Veuillez renseigner l'email"),
    password: Yup.string().required(`Veuillez renseigner le mot de passe`),
    firstname: Yup.string().required(`Veuillez renseigner le prénom`),
    lastname: Yup.string().required("Veuillez renseigner le nom"),
    company: Yup.string().max(255).required("Veuillez renseigner la société"),
    refstatus: Yup.string().max(255).required("Veuillez renseigner le statut"),
    /*  role: Yup.string().max(255).required("Veuillez renseigner le role"), */
    projectrole: Yup.string()
      .max(255)
      .required("Veuillez renseigner le pseudo"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      pseudo: editUser ? editUser.pseudo : "",
      email: editUser ? editUser.email : "",
      password: editUser ? "passwordUserNotChanged" : "",
      firstname: editUser ? editUser.firstname : "",
      lastname: editUser ? editUser.firstname : "",
      company: editUser ? editUser.company.id : "",
      refstatus: editUser ? editUser.refstatus.id : "",
      /*  role: editUser ? editUser.role.id : "", */
      projectrole:
        editUser && editUser.projectrole ? editUser.projectrole.id : "",
    },
    validationSchema: UserSchema,
    onSubmit: async () => {
      try {
        const newU =
          editUser && editUser.id ? { ...editUser, ...values } : { ...values };
        await saveUser(newU);
        await initUsers();
        notif("Sauvegarde réussie");
        onComplete();
      } catch (error) {
        console.error(error);
        notif(null, error);
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    handleChange,
    setFieldValue,
  } = formik;

  const companies = Array.isArray(refs.companies) ? refs.companies : [];

  const refstatus = Array.isArray(refs.refstatus) ? refs.refstatus : [];
  const roleapp = Array.isArray(refs.roleapp) ? refs.roleapp : [];
  const projects = () => {
    try {
      if (!Array.isArray(refs.projectrole)) return [];

      const arr = [];
      refs.projectrole.forEach((it) => {
        arr.push({
          code: it.id,
          name: `${it.project.name} - ${it.label}`,
        });
      });
      return arr;
    } catch (error) {
      return [];
    }
  };
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            fullWidth
            type="text"
            name="pseudo"
            label="Pseudo"
            {...getFieldProps("pseudo")}
            error={Boolean(touched.pseudo && errors.pseudo)}
            helperText={touched.pseudo && errors.pseudo}
            my={2}
          />
          <TextField
            fullWidth
            type="email"
            name="email"
            label="Email"
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="firstname"
            label="Prénom"
            {...getFieldProps("firstname")}
            error={Boolean(touched.firstname && errors.firstname)}
            helperText={touched.firstname && errors.firstname}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="lastname"
            label="Nom"
            {...getFieldProps("lastname")}
            error={Boolean(touched.lastname && errors.lastname)}
            helperText={touched.lastname && errors.lastname}
            my={2}
          />
          {!editUser && (
            <>
              <CodeGen
                name="password"
                label="Mot de passe"
                title="Générer le mot de passe"
                placeholder="Mot de passe"
                value={values.password}
                onChange={(val) => {
                  setFieldValue("password", val);
                }}
                error={Boolean(touched.password && errors.password)}
              />

              {errors.password && (
                <FormHelperText error>{errors.password} </FormHelperText>
              )}
            </>
          )}

          <TextField
            fullWidth
            select
            label="Statut"
            value={values.refstatus}
            onChange={handleChange("refstatus")}
            {...getFieldProps("refstatus")}
            error={Boolean(touched.refstatus && errors.refstatus)}
            helperText={touched.refstatus && errors.refstatus}
            my={2}
          >
            {refstatus.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            select
            label="Société"
            value={values.company}
            onChange={handleChange("company")}
            {...getFieldProps("company")}
            error={Boolean(touched.company && errors.company)}
            helperText={touched.company && errors.company}
            my={2}
          >
            {companies.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          {/* <TextField
            fullWidth
            select
            label="Role applicatif"
            value={values.role}
            onChange={handleChange("role")}
            {...getFieldProps("role")}
            error={Boolean(touched.role && errors.company)}
            helperText={touched.company && errors.company}
            my={2}
          >
            {roleapp.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField> */}
          <TextField
            fullWidth
            select
            label="Projet et role"
            value={values.projectrole}
            onChange={handleChange("projectrole")}
            {...getFieldProps("projectrole")}
            error={Boolean(touched.projectrole && errors.projectrole)}
            helperText={touched.projectrole && errors.projectrole}
            my={2}
          >
            {projects().map((option, i) => (
              <MenuItem key={i} value={option.code}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
}
