import React from "react";

import {
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Add as AddIcon } from "@mui/icons-material";
import UserForm from "./UserForm";
// ----------------------------------------------------------------------
export default function UserEditBtn({ editUser }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const getTitle = () => {
    try {
      if (editUser && editUser.id) {
        return `Modification`;
      } else {
        return "Nouvel utilisateur";
      }
    } catch (error) {
      return "";
    }
  };
  const getIcon = () => {
    try {
      if (editUser && editUser.id) {
        return (
          <IconButton
            aria-label="details"
            size="large"
            onClick={handleClickOpen}
          >
            <EditIcon />
          </IconButton>
        );
      } else {
        return (
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            <AddIcon />
            {getTitle()}
          </Button>
        );
      }
    } catch (error) {
      return <></>;
    }
  };
  return (
    <div>
      {getIcon()}
      <Dialog open={open} fullWidth>
        <DialogTitle> {getTitle()}</DialogTitle>
        <DialogContent>
          <UserForm editUser={editUser} onComplete={() => handleClose()} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
