import { API_URL } from "config";

export const getObjectWithoutPropsNull = (myObject) => {
  try {
    const newObject = {};
    Object.keys(myObject).forEach(function (key) {
      if (
        typeof myObject[key] !== "undefined" &&
        myObject[key] !== null &&
        myObject[key] !== ""
      ) {
        newObject[key] = myObject[key];
      }
    });
    return newObject;
  } catch (error) {
    return myObject;
  }
};
export const getMediaUrl = (it, imgField, refName) => {
  try {
    const imgValue = it[imgField];
    if (!it || !imgValue) return null;
    return `${API_URL.getstream}/${refName}_${it.id}/${imgValue}`;
  } catch (error) {
    console.error(error);
    return null;
  }
};
