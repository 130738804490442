import React from "react";
import styled from "@emotion/styled";
import { Link as RouterLink } from "react-router-dom";

import { Button as MuiButton, Typography } from "@mui/material";
import { spacing } from "@mui/system";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  text-align: center;
  background: transparent;
`;

// ----------------------------------------------------------------------

export default function ErrorAppFallback({ error, resetErrorBoundary }) {
  return (
    <Wrapper>
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        500
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        Désolé, une erreur s'est produite.
      </Typography>
      <Button
        onClick={() => {
          setTimeout(() => {
            window.location.reload();
          }, 100);
        }}
        component={RouterLink}
        to="/app/dashboard"
        variant="contained"
        color="secondary"
        mt={2}
      >
        Retourner à l'accueil
      </Button>
    </Wrapper>
  );
}
