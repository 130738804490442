import * as React from "react";
import useAuth from "hooks/useAuth";
import useReferentiel from "hooks/useReferentiel";
// ----------------------------------------------------------------------
const AppContext = React.createContext();
export default function AppProvider({ ...props }) {
  const { initAuth } = useAuth();
  const { initRefs } = useReferentiel();
  React.useEffect(() => {
    initAuth();
    initRefs();
  }, []);

  return <AppContext.Provider value={{}}>{props.children}</AppContext.Provider>;
}
