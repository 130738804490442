import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "hooks/useAuth";
import { PATH_PAGE } from "paths";
import LoadingScreen from "components/LoadingScreen";
// ----------------------------------------------------------------------
export default function RequireAuth({ children }) {
  const { isLoading, isAuthenticated } = useAuth();
  const location = useLocation();

  if (isLoading) {
    return <LoadingScreen />;
  }
  return isAuthenticated === true ? (
    children
  ) : (
    <Navigate
      to={PATH_PAGE.auth.login}
      replace
      state={{ path: location.pathname }}
    />
  );
}
