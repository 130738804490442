import { useDispatch, useSelector } from "react-redux";
import { startLoading, setInitialize } from "redux/slices/userSlice";
import axios from "axios";
import { API_URL } from "config";

// ----------------------------------------------------------------------

export default function useDataUser() {
  const dispatch = useDispatch();
  const { users, isLoading } = useSelector((state) => state.users);

  return {
    users,
    isLoading,
    initUsers: async () => {
      try {
        dispatch(startLoading());
        const res = await axios.get(API_URL.users);
        if (res && res.data) {
          const users = res.data;
          dispatch(setInitialize({ users }));
        } else {
          throw new Error("UsersData ne sont pas disponibles");
        }
      } catch (error) {
        console.error();
        throw new Error("Référentiels ne sont pas disponibles");
      }
    },
    saveUser: async (u) => {
      try {
        if (u && u.id) {
          const res = await axios.patch(API_URL.users + "/" + u.id, u);
        } else {
          const res = await axios.post(API_URL.users, u);
        }
      } catch (error) {
        console.error();
        throw error;
      }
    },
    changePassword: async (u, password) => {
      try {
        if (!u) return;
        const res = await axios.post(
          API_URL.users + "/change-password/" + u.id,
          { password }
        );
      } catch (error) {
        console.error();
        throw error;
      }
    },
  };
}
